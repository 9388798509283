import * as types from '../../mutation-types'
import lazyLoading from './lazyLoading'

// show: meta.label -> name
// name: component name
// meta.label: display label
const state = {
  items: [
    // {
    //   name: 'Index',
    //   path: '/',
    //   meta: {
    //     icon: 'fa-tachometer',
    //     label: 'Dashboard'
    //   },
    //   component: lazyLoading('Widgets', false)
    // },
    // {
    //   name: 'MainPageSettings',
    //   path: '/mainpage',
    //   meta: {
    //     icon: 'window',
    //     label: "Главная страница",
    //     inSideBar: true,
    //   },
    //   component: lazyLoading('MainPage', false),
    //   children: [
    //     {
    //       name: 'PinnedLinks',
    //       path: 'pinned-links',
    //       meta: {
    //         label: 'Pinned links',
    //       },
    //       component: lazyLoading('PinnedLinks', false)
    //     },
    //     {
    //       name: 'Banner',
    //       path: 'banner',
    //       meta: {
    //         label: 'Баннер'
    //       },
    //       component: lazyLoading('BannerSlides', false)
    //     },
    //   ]
    // },
    {
      name: 'Main',
      path: '/',
      meta: {
        inSideBar: false
      },
      component: lazyLoading('Index')
    },
    {
      name: 'Config',
      path: '/config',
      meta: {
        icon: 'fa-gear',
        label: 'Настройки',
        can: 'show Config',
      },
      component: lazyLoading('config/Config', false)
    },
    {
      name: 'Users',
      path: '/users',
      meta: {
        icon: 'fa-user',
        label: 'Пользователи',
        expanded: false,
        exact: false,
        can: 'show User'
      },
      component: lazyLoading('users/Users', false)
    },
    {
      name: 'User',
      path: '/users/:id',
      meta: {
        label: 'Пользователь',
        inSideBar: false,
        can: 'show User',
        fail: '/users'
      },
      component: lazyLoading('users/User', false)
    },


    {
      name: 'WhatDoWeTreat',
      path: '/wdwt',
      redirect: { name: 'Symptoms' },
      meta: {
        can: 'show WhatDoWeTreat',
        label: "Что мы лечим",
      },
      component: lazyLoading('what-do-we-treat/Index', false),
      children: [
        {
          name: 'Symptoms',
          path: 'symptoms',
          meta: {
            inSideBar: false,
            label: 'Симптомы',
          },
          component: lazyLoading('what-do-we-treat/Symptoms', false),
          children: [
            {
              name: 'Symptom',
              path: ':id',
              meta: {
                label: 'Симптом',
                inSideBar: false,
                can: 'show WhatDoWeTreat',
              },
              component: lazyLoading('what-do-we-treat/Symptom', false)
            },
          ]
        },
        {
          name: 'Diseases',
          path: 'diseases',
          meta: {
            label: 'Болезни',
            inSideBar: false,
          },
          component: lazyLoading('what-do-we-treat/Diseases', false),
          children: [
            {
              name: 'Disease',
              path: ':id',
              meta: {
                label: 'Болезнь',
                inSideBar: false,
              },
              component: lazyLoading('what-do-we-treat/Disease', false)
            },
          ]
        },
        {
          name: 'Procedures',
          path: 'procedures',
          meta: {
            label: 'Процедуры',
            inSideBar: false,
          },
          component: lazyLoading('what-do-we-treat/Procedures', false),
          children: [
            {
              name: 'Procedure',
              path: ':id',
              meta: {
                label: 'Процедура',
                inSideBar: false,
                can: 'show WhatDoWeTreat',
              },
              component: lazyLoading('what-do-we-treat/Procedure', false)
            },
          ]
        },
      ]
    },

    {
      name: 'Ban',
      path: '/ban',
      meta: {
        icon: 'fa-ban',
        label: 'Ban',
        can: 'show Ban',
      },
      component: lazyLoading('Ban')
    },


    {
      name: 'CaptchaExceptions',
      path: '/captcha-exceptions',
      meta: {
        can: 'show Captcha',
        label: 'Captcha'
      },
      component: lazyLoading('captcha/CaptchaExceptions', false)
    },
    {
      name: 'InformerNotificationsList',
      path: '/notifications',
      meta: {
        label: 'Перетяжки',
        icon: 'notification_important',
        can: 'show InformerNotifications',
        inSideBar: true
      },
      component: lazyLoading('informer/Notifications', false)
    },
    {
      name: 'InformerNotificationEdit',
      path: '/notifications/:id',
      meta: {
        label: 'Перетяжка',
        inSideBar: false,
        can: 'show InformerNotifications',
        fail: '/notifications'
      },
      component: lazyLoading('informer/Notification', false)
    },

    {
      name: 'ProtectedReports',
      redirect: { name: 'Receivables' },
      meta: {
        icon: 'fa-calc',
        label: 'Закрытые отчеты',
      },
      path: '/protected-reports',
      component: lazyLoading('bi/Protected', false),
      children: [
        {
          name: 'Receivables',
          path: 'receivables',
          meta: {
            onlyMainTenant: true,
            label: 'Дебиторская задолженность',
            can: 'show Receivables',
            inSideBar: false
          },
          component: lazyLoading('bi/Receivables', false)
        },
        {
          name: 'PaysheetsChiefPractice',
          path: 'paysheets-chief-practice',
          meta: {
            onlyMainTenant: true,
            label: 'Расчетный лист',
            sublabel: 'для глав. врачей (практики)',
            can: 'show PaysheetsChiefPractice',
            inSideBar: false,
          },
          component: lazyLoading('bi/PaysheetsChiefPractice', false)
        },
        {
          name: 'WagesFundPractices',
          path: 'wages-fund-practices',
          meta: {
            label: 'Загрузка',
            sublabel: 'по практикам',
            can: 'show WagesFund',
            inSideBar: false,
          },
          component: lazyLoading('bi/WagesFundPractices', false)
        },
        {
          name: 'WagesFundPracticesPL',
          path: 'pl-practices-test',
          meta: {
            label: 'Загрузка',
            sublabel: '(графики)',
            can: 'show WagesFund',
            inSideBar: false,
          },
          component: lazyLoading('bi/PLPracticesTest', false)
        },
        {
          name: 'PracticesInsurance',
          path: 'practices-insurance',
          meta: {
            onlyMainTenant: true,
            label: 'Отчет для КЭР',
            can: 'show PracticesInsurance',
            inSideBar: false,
          },
          component: lazyLoading('bi/PracticesInsurance', false)
        },
      ]
    },


    {
      name: 'Divider1',
      path: '/divivder',
      meta: {
        label: '--------------------------'
      },
      component: lazyLoading('Divider'),
    },



    // {
    //   name: 'Booking',
    //   path: '/booking',
    //   meta: {
    //     icon: 'fa-calendar',
    //     label: 'Запись на прием',
    //     can: 'show Booking'
    //   },
    //   component: lazyLoading('Booking', false)
    // },
    {
      name: 'Documents',
      path: '/documents',
      meta: {
        icon: 'fa-file-text',
        label: 'Документы',
        can: 'show Documents',
      },
      component: lazyLoading('documents/Documents', false)
    },
    {
      name: 'Document',
      path: '/documents/:id',
      meta: {
        label: 'Документ',
        inSideBar: false,
        // can: 'show Document',
        fail: '/documents'
      },
      component: lazyLoading('documents/Document', false)
    },

    {
      name: 'Cart',
      meta: {
        icon: 'fa-calculator',
        label: 'Калькуляция услуг',
        expanded: false,
        can: 'show Cart'
      },
      redirect: { name: 'common-calculator' },
      path: '/calc',
      component: lazyLoading('Calculation'),
      children: [
        {
          name: 'common-calculator',
          path: 'calculator',
          meta: {
            label: 'Общий калькулятор',
            inSideBar: false,
            can: 'show Cart'
          },
          component: lazyLoading('calculator/index', false),
        },
        {
          name: 'DentistryCalculator',
          path: 'calculation/dentistry',
          meta: {
            onlyMainTenant: true,
            inSideBar: false,
            label: 'Стоматология',
            can: 'show Cart'
          },
          component: lazyLoading('cart/Cart', false),
        },
      ]
    },
    // {
    //   name: 'Clinics',
    //   path: '/clinics',
    //   meta: {
    //     label: 'Клиники',
    //     can: 'show Clinic',
    //     fail: '/'
    //   },
    //   component: lazyLoading('clinics/Clinics', false)
    // },
    // {
    //   name: 'Clinic',
    //   path: '/clinics/:id',
    //   meta: {
    //     label: 'Клиника',
    //     inSideBar: false,
    //     can: 'show Clinic',
    //     fail: '/clinics'
    //   },
    //   component: lazyLoading('clinics/Clinic', false)
    // },

    // {
    //   name: 'Doctors',
    //   path: '/doctors',
    //   meta: {
    //     label: 'Врачи',
    //     can: 'show Doctor',
    //     fail: '/'
    //   },
    //   component: lazyLoading('doctors/Doctors', false)
    // },
    // {
    //   name: 'Doctor',
    //   path: '/doctors/:id',
    //   meta: {
    //     label: 'Доктор',
    //     inSideBar: false,
    //     can: 'show Doctor',
    //     fail: '/doctors'
    //   },
    //   component: lazyLoading('doctors/Doctor', false)
    // },

    // {
    //   name: 'Services',
    //   path: '/services',
    //   meta: {
    //     label: 'Услуги',
    //     exact: true,
    //     can: 'show Service',
    //     fail: '/'
    //   },
    //   component: lazyLoading('services/Services', false)
    // },
    // {
    //   name: 'Service',
    //   path: '/services/:id',
    //   meta: {
    //     label: 'Услуга',
    //     inSideBar: false,
    //     can: 'show Service',
    //     fail: '/services'
    //   },
    //   component: lazyLoading('services/Service', false)
    // },

    // {
    //   name: 'Specialities',
    //   path: '/specialities',
    //   meta: {
    //     label: 'Специальности',
    //     can: 'show Speciality',
    //     fail: '/'
    //   },
    //   component: lazyLoading('specialities/Specialities', false)
    // },
    // {
    //   name: 'Speciality',
    //   path: '/specialities/:id',
    //   meta: {
    //     label: 'Специальность',
    //     inSideBar: false,
    //     can: 'show Speciality',
    //     fail: '/specialities'
    //   },
    //   component: lazyLoading('specialities/Speciality', false)
    // },

    // {
    //   name: 'ClinicPractice',
    //   path: '/clinics/:clinicId/practices/:id',
    //   props: true,
    //   meta: {
    //     inSideBar: false,
    //     can: 'show Clinic',
    //   },
    //   component: lazyLoading('clinics/ClinicPractice', false),
    // },


    // {
    //   name: 'ServiceCategory',
    //   path: '/service-categories/:id',
    //   meta: {
    //     label: 'Категория услуги',
    //     inSideBar: false,
    //     can: 'show Service',
    //     fail: '/services'
    //   },
    //   component: lazyLoading('services/Category', false)
    // },
    // {
    //   name: 'Reports',
    //   meta: {
    //     label: 'Реестры',
    //     icon: 'fa-line-chart',
    //     expanded: false
    //   },
    //   children: [
    //     {
    //       name: 'Appointments',
    //       path: '/appointments',
    //       meta: {
    //         label: 'Записи на прием',
    //         exact: true,
    //         can: 'show Appointment'
    //       },
    //       component: lazyLoading('appointments/Appointments', false)
    //     },
    //     {
    //       name: 'MultiAppointments',
    //       path: '/multi-appointments',
    //       meta: {
    //         label: 'Записи на программы',
    //         exact: true,
    //         can: 'show Appointment'
    //       },
    //       component: lazyLoading('appointments/MultiAppointments', false)
    //     },
    //     {
    //       name: 'Payments',
    //       path: '/payments',
    //       meta: {
    //         label: 'Покупки',
    //         exact: true,
    //         can: 'show Payment'
    //       },
    //       component: lazyLoading('payments/Payments', false)
    //     },
    //     {
    //       name: 'Search',
    //       path: '/searchterm',
    //       meta: {
    //         label: 'Статистика поиска',
    //         exact: true,
    //         can: 'show SearchTerm'
    //       },
    //       component: lazyLoading('search/Search', false)
    //     },
    //     {
    //       name: 'MedicalCardHistory',
    //       path: '/mchistories',
    //       meta: {
    //         label: 'Статистика поиска ЛК',
    //         exact: true,
    //         can: 'show MedicalCardHistory'
    //       },
    //       component: lazyLoading('mchistory/MedicalCardHistory', false)
    //     },
    //     {
    //       name: 'UserFileHistory',
    //       path: '/user-file-histories',
    //       meta: {
    //         label: 'Статистика загрузок',
    //         exact: true,
    //         can: 'show UserFileHistory'
    //       },
    //       component: lazyLoading('user-file-history/History', false)
    //     }
    //   ]
    // },
    // {
    //   name: 'BlogPosts',
    //   path: '/blog/posts',
    //   meta: {
    //     label: 'Блог',
    //     icon: 'fa-newspaper-o',
    //     inSideBar: true,
    //     can: 'show Post',
    //     fail: '/'
    //   },
    //   component: lazyLoading('blog/Posts', false)
    // },
    // {
    //   name: 'BlogPost',
    //   path: '/blog/posts/:id',
    //   meta: {
    //     label: 'Статья',
    //     inSideBar: false,
    //     can: 'show Post',
    //     fail: '/blog/posts'
    //   },
    //   component: lazyLoading('blog/Post', false)
    // },
    // {
    //   name: 'RequestForms',
    //   path: '/request-forms',
    //   meta: {
    //     onlyMainTenant: true,
    //     icon: 'fa-tachometer',
    //     label: 'Формы-заявки',
    //     can: 'show RequestForm',
    //   },
    //   component: lazyLoading('request-forms/RequestForms', false)
    // },
    // {
    //   name: 'RequestForm',
    //   path: '/request-forms/:id',
    //   meta: {
    //     onlyMainTenant: true,
    //     inSideBar: false,
    //     can: 'show RequestForm',
    //   },
    //   component: lazyLoading('request-forms/RequestForm', false)
    // },
    {
      name: 'Photos',
      path: '/photos',
      meta: {
        label: 'Фотопоток',
        icon: 'fa-file-photo-o',
        inSideBar: true,
        can: 'show Photo',
        fail: '/'
      },
      component: lazyLoading('files/Photos', false)
    },
    {
      name: 'Photo',
      path: '/photos/:id',
      meta: {
        label: 'Изображение',
        inSideBar: false,
        can: 'show Photo',
        fail: '/photos'
      },
      component: lazyLoading('files/Photo', false)
    },
    {
      name: 'Files',
      path: '/files',
      meta: {
        label: 'Файлы',
        icon: 'fa-file',
        inSideBar: true,
        can: 'show Files',
        fail: '/'
      },
      component: lazyLoading('files/Files', false)
    },
    {
      name: 'File',
      path: '/files/:id',
      meta: {
        label: 'Файл',
        inSideBar: false,
        can: 'show File',
        fail: '/'
      },
      component: lazyLoading('files/File', false)
    },

    // {
    //   name: 'ScheduleOfDoctor',
    //   path: '/schedule-of-doctors/:id',
    //   meta: {
    //     label: 'Расписание врача',
    //     inSideBar: false
    //   },
    //   component: lazyLoading('appointments/ScheduleOfDoctor', false)
    // },
    // {
    //   name: 'HouseCallMap',
    //   path: '/house-call-maps/:id',
    //   meta: {
    //     onlyMainTenant: true,
    //     label: 'Вызов на дом',
    //     inSideBar: false,
    //     can: 'update HouseCallMap',
    //     fail: '/house-call-maps'
    //   },
    //   component: lazyLoading('appointments/HouseCallMap', false)
    // },




    // {
    //   name: 'Collaborators',
    //   meta: {
    //     label: 'ЛК МедПрофи',
    //     icon: 'fa-heartbeat',
    //     expanded: false
    //   },
    //   children: [
    //     {
    //       name: 'CollabDoctors',
    //       path: '/collaborate/users',
    //       meta: {
    //         label: 'Врачи',
    //         can: 'show Collab',
    //         fail: '/collaborate'
    //       },
    //       component: lazyLoading('collaborate/Users', false)
    //     },
    //     {
    //       name: 'CollabOrganizations',
    //       path: '/collaborate/org',
    //       meta: {
    //         label: 'Организации',
    //         can: 'show Collab',
    //         fail: '/collaborate'
    //       },
    //       component: lazyLoading('collaborate/Org', false)
    //     },
    //     {
    //       name: 'CollabPayments',
    //       path: '/collaborate/payments',
    //       meta: {
    //         label: 'Выплаты',
    //         can: 'show Collab',
    //         fail: '/collaborate'
    //       },
    //       component: lazyLoading('collaborate/Payments', false)
    //     }
    //   ]
    // },
    // {
    //   name: 'CollabDoctor',
    //   path: '/collaborate/:id',
    //   meta: {
    //     label: 'Врач',
    //     inSideBar: false,
    //     can: 'show Collab',
    //     fail: '/collaborate'
    //   },
    //   component: lazyLoading('collaborate/User', false)
    // },


    // {
    //   name: 'RefundRequest',
    //   path: '/refunds/request',
    //   meta: {
    //     label: 'Запрос на возврат',
    //     icon: 'fa-money',
    //     inSideBar: true
    //   },
    //   component: lazyLoading('payments/RefundRequest', false)
    // },
    // {
    //   name: 'Cmes',
    //   path: '/cmes',
    //   meta: {
    //     label: 'CME',
    //     icon: 'fa-universal-access',
    //     inSideBar: true
    //   },
    //   component: lazyLoading('cme/Cme', false)
    // },
    // {
    //   name: 'Cme',
    //   path: '/cmes/:id',
    //   meta: {
    //     label: 'CME',
    //     icon: 'fa-universal-access',
    //     inSideBar: false
    //   },
    //   component: lazyLoading('cme/CmeItem', false)
    // },

    {
      name: 'Pages',
      path: '/pages',
      meta: {
        label: 'Страницы',
        icon: 'fa-file-powerpoint-o',
        inSideBar: true,
        can: 'show Pages',
        fail: '/'
      },
      component: lazyLoading('pages/Pages', false)
    },
    {
      name: 'Page',
      path: '/pages/:id',
      meta: {
        label: 'Страница',
        inSideBar: false,
        fail: '/pages'
      },
      component: lazyLoading('pages/Page', false)
    },


    {
      name: 'AllReports',
      redirect: { name: 'PLPracticesDoctor' },
      meta: {
        icon: 'fa-calc',
        label: 'Отчеты',
      },
      path: '/private-reports',
      component: lazyLoading('bi/Index', false),
      children: [
        {
          name: 'PLPracticesDoctor',
          path: 'pl-practices-doctor',
          meta: {
            label: 'PL практик для докторов',
            can: 'show PLPracticesDoctor',
            inSideBar: false,
          },
          component: lazyLoading('bi/PLPracticesDoctor', false)
        },
        {
          name: 'OperatingIncomeTest',
          path: 'bi-test',
          meta: {
            onlyMainTenant: true,
            label: 'Операционная прибыль (test)',
            can: 'show OperatingIncome',
            inSideBar: false
          },
          component: lazyLoading('bi/OperatingIncomeTest', false)
        },
        {
          name: 'CallStatistics',
          path: 'call-statistics',
          meta: {
            onlyMainTenant: true,
            label: 'Статистика по звонкам',
            can: 'show CallStatistics',
            inSideBar: false
          },
          component: lazyLoading('bi/CallStatistics', false)
        },
        {
          name: 'PatientDebts',
          path: 'patient-debts',
          meta: {
            onlyMainTenant: true,
            label: 'Долги пациентов',
            can: 'show PatientDebts',
            inSideBar: false,
          },
          component: lazyLoading('bi/PatientDebts', false)
        },
        {
          name: 'OperatingIncomePractices',
          path: 'operating-income-practices',
          meta: {
            onlyMainTenant: true,
            label: 'Отчет',
            sublabel: 'для руководителей практик',
            can: 'show OperatingIncomePractices',
            inSideBar: false,
          },
          component: lazyLoading('bi/OperatingIncomePractices', false)
        },
        {
          name: 'PracticesSalaryForDoctors',
          path: 'practices-salary-for-doctors',
          meta: {
            onlyMainTenant: true,
            label: 'Расчетный лист',
            sublabel: 'для докторов практик',
            can: 'show PracticesSalaryForDoctors',
            inSideBar: false,
          },
          component: lazyLoading('bi/PracticesSalaryForDoctors', false)
        },
        {
          name: 'DoctorsRates',
          path: 'doctors-rates',
          meta: {
            onlyMainTenant: true,
            label: 'Ставки вознаграждения',
            sublabel: 'по практикам',
            can: 'show DoctorsRates',
            inSideBar: false,
          },
          component: lazyLoading('bi/DoctorsRates', false)
        },
        {
          name: 'TaxDeductions',
          path: 'tax-deductions',
          meta: {
            onlyMainTenant: true,
            label: 'Налоговые вычеты',
            inSideBar: false,
            fail: '/',
            can: 'show TaxDeductions'
          },
          component: lazyLoading('tax-deduction/TaxDeductions', false),
        },
        {
          name: 'TaxDeduction',
          path: 'tax-deductions/:id',
          meta: {
            // onlyMainTenant: true,
            label: 'Налоговый вычет',
            inSideBar: false,
            inTabs: false,
            fail: '/',
            can: 'show TaxDeduction'
          },
          component: lazyLoading('tax-deduction/TaxDeduction', false)
        },
        {
          name: 'QueuePhones',
          path: 'queue-phones',
          meta: {
            onlyMainTenant: true,
            label: 'Очереди телефонии',
            inSideBar: false,
            can: 'show QueuePhones'
          },
          component: lazyLoading('asterisk/QueuePhones', false)
        },
      ]
    },

    // {
    //   name: 'Practices',
    //   path: '/practices',
    //   meta: {
    //     onlyMainTenant: true,
    //     label: 'Практики',
    //     icon: 'fa-calculator',
    //     can: 'show Practices',
    //   },
    //   component: lazyLoading('bi/Practices', false)
    // },
    {
      name: 'PaymentRequest',
      path: 'payment-request',
      meta: {
        label: 'Заявка на оплату',
        can: 'show PaymentRequest',
        inSideBar: true,
      },
      component: lazyLoading('PaymentRequest', false)
    },

    {
      name: 'Cache',
      path: '/cache',
      meta: {
        icon: 'mdi-broom',
        label: 'Кэш',
        inSideBar: true,
        can: 'show Cache'
      },
      component: lazyLoading('Cache', false)
    },
  ]
}

const mutations = {
  [types.EXPAND_MENU] (state, menuItem) {
    if (menuItem.index > -1) {
      if (state.items[menuItem.index] && state.items[menuItem.index].meta) {
        state.items[menuItem.index].meta.expanded = menuItem.expanded
      }
    } else if (menuItem.item && 'expanded' in menuItem.item.meta) {
      menuItem.item.meta.expanded = menuItem.expanded
    }
  }
}

export default {
  state,
  mutations
}
